<template>
  <div>
    <div class="regiterContainer">
        <el-form ref="registerForm" :model="registerFormData" :rules="registerFormRules">
            <el-form-item label="姓名" prop="userName">
                <el-input v-model="registerFormData.userName" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系人">
                <el-input v-model="registerFormData.contactName" placeholder="请输入您的联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input v-model="registerFormData.contactPhone" placeholder="请输入您的联系电话"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="passWord">
                <el-input v-model="registerFormData.passWord" type="password" placeholder="请输入您的密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassWord">
                <el-input v-model="registerFormData.confirmPassWord" type="password" placeholder="请确认您的密码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" class="login-form-code-item" prop="code">
                <el-input v-model="registerFormData.code" placeholder="请输入您的验证码"></el-input>
                <div class="login-form-code-img">
                    <img :src="codeImg" alt="">
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="hanleClickRegister">注册</el-button>
            </el-form-item>
        </el-form>
    </div>
  </div>
</template>

<script>
import { Register, getCode } from '../../api/api.js'
export default {
    data() {
        return {
            registerFormData: {
                userName: "",
                contactName: "",
                contactPhone: "",
                passWord: "",
                confirmPassWord: "",
                code: ""
            },
            registerFormRules: {
                userName: [
                    { required: true, message: '请输入您的姓名', trigger: 'blur' }
                ],
                contactName: [
                    { required: false, message: '请输入您的联系人', trigger: 'blur' }
                ],
                contactPhone: [
                    { required: false, message: '请输入您的联系电话', trigger: 'blur' }
                ],
                passWord: [
                    { required: true, message: '请输入您的密码', trigger: 'blur' }
                ],
                confirmPassWord: [
                    { required: true, message: '请确认您的密码', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入您的验证码', trigger: 'blur' }
                ]
            },
            // 二维码图片
            codeImg: '',
            // 二维码的唯一标识,给后端判断的
            uniqid: ''
        }
    },
    created() {
        this.getQrCode()
    },
    mounted() {
        // 按下回车验证注册
        document.onkeydown = () => {
        var key = window.event.keyCode
        if (key === 13) {
            this.hanleClickRegister()
        }
        }
    },
    methods: {
        hanleClickRegister() {
            this.$refs['registerForm'].validate((valid) => {
                if(valid) {
                     if(this.registerFormData.passWord != this.registerFormData.confirmPassWord) {
                        this.$message.error({
                            message: "两次输入的密码不一致"
                        })
                    }else {
                        Register(
                            this.registerFormData.userName,
                            this.registerFormData.contactName,
                            this.registerFormData.contactPhone,
                            this.registerFormData.passWord,
                            this.registerFormData.confirmPassWord,
                            this.registerFormData.code,
                            this.uniqid
                        )
                        .then(res => {
                            if(res.data.code == 1) {
                                this.$message({
                                    type: "success",
                                    message: "注册成功"
                                })
                                this.$router.push("/login")
                            }else if(res.data.code == 2) {
                                if(res.data.msg == '联系电话格式不对') {
                                    this.$message({
                                        type: "error",
                                        message: "联系电话格式不对,请重新输入手机号"
                                    })
                                }else if(res.data.msg == '验证码错误') {
                                    this.$message({
                                        type: "error",
                                        message: "验证码错误,请重新输入验证码"
                                    })
                                    this.getQrCode()
                                }
                            } 
                        })
                    }
                }
            })
        },
        // 获取二维码图片
        getQrCode() {
            getCode()
            .then(res => {
                this.codeImg = res.data.content
                this.uniqid = res.data.uniqid
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.regiterContainer {
    width: 800px;
    height: 800px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    .login-form-code-item {
        position: relative;
        .login-form-code-img {
            position: absolute;
            left: 215px;
            top: 17px;
            width: 200px;
            height: 90px;
            img {
                width: 100%;
                height: 100%;
            }
        }   
    }
}
</style>